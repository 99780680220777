import React from "react";
import BreadcrumbsV2 from "../breadcrumbsV2";
import { Spinner } from "../../style/Spinner";
import { Flex, Box, Container, Wrapper } from "../../style/Grid";
import { ACTIVATION_BREAKCRUMBS_TITLE } from "./constants";

export const LoadingScreen = ({ breadcrumbsText }) => (
  <Wrapper background="linear-gradient(180deg, #A8BE32 0%, #2B6E33 100%);" pb="95px" minHeight={"calc(100vh - 497px)"}>
    <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
      <Container px={["15px", null, null, null, null]}>
        <BreadcrumbsV2
          pages={[
            {
              name: ACTIVATION_BREAKCRUMBS_TITLE(breadcrumbsText),
              link: null,
            },
          ]}
          color={"white.100"}
        />
      </Container>
      <Container alignItems={"center"} px={["15px", null, null, null, null]} position={"relative"}>
        <Box mt={"185px"} mb={"185px"}>
          <Spinner />
        </Box>
      </Container>
    </Flex>
  </Wrapper>
);
