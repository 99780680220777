import styled from "styled-components";

const Line = styled.span`
    margin: 20px 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #A8BE32;
`;

Line.displayName = "Line";

export { Line };