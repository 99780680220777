import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layoutMinimum";
import { ThemeProvider } from "styled-components";
import { theme } from "../../style/theme";
import { Activation } from "../../components/Activation/activation";
import { SHOW_TEST_PANEL } from "../../components/Activation/constants";

export default ({ location, pageContext }) =>
{
    const hash = location && location.search ? location.search.substring(location.search.lastIndexOf("?") + 1).split("=")[0] : "";
    const debug = SHOW_TEST_PANEL && location && location.search.includes("=") || false;
    const seo = pageContext.SEO || {};
    const keywords = seo.keywords || "";
    const description = seo.description || "";
    const title = seo.title || "";
    const inactiveHeadline = pageContext.inactiveHeadline;
    const inactiveParagraph = pageContext.inactiveParagraph;
    const activeHeadline = pageContext.activeHeadline;
    const activeParagraph = pageContext.activeParagraph;
    const activeParagraph2 = pageContext.activeParagraph2;
    const unavailableHeadline = pageContext.unavailableHeadline;
    const unavailableParagraph = pageContext.unavailableParagraph;
    const canceledHeadline = pageContext.canceledHeadline;
    const canceledParagraph = pageContext.canceledParagraph;
    const errorHeadline = pageContext.errorHeadline;
    const errorParagraph = pageContext.errorParagraph;
    const priceListId = pageContext.priceListId;

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Helmet>
                    <title>{title}</title>
                    <meta name="keywords" content={keywords} />
                    <meta name="description" content={description} />
                    <meta name="og:description" content={description} />
                </Helmet>
                <Activation context={{
                    hash,
                    title,
                    inactiveHeadline,
                    inactiveParagraph,
                    activeHeadline,
                    activeParagraph,
                    activeParagraph2,
                    unavailableHeadline,
                    unavailableParagraph,
                    canceledHeadline,
                    canceledParagraph,
                    errorHeadline,
                    errorParagraph,
                    priceListId,
                    debug
                }} />
            </Layout>
        </ThemeProvider>
    );
};
