import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Table = styled("table")`
  max-width: 650px;
  width: inherit;
  padding: 15px;

  th {
    padding: 10px 14px;
    background: ${themeGet("colors.resourceGreen")};
    font-size: ${themeGet("fontSizes.0")};
    font-weight: ${themeGet("fontWeights.bold")};
    color: ${themeGet("colors.white.100")};
  }

  td {
    padding: 10px 14px;
    background: ${themeGet("colors.lightGrey")};
    font-size: ${themeGet("fontSizes.0")};
  }

  @media screen and (max-width: 599px) {
    td,
    th {
      line-height: 20px;
      padding: 5px;
      font-size: 12px;
    }
  }
`;

Table.displayName = "Table";

Table.defaultProps = {
  backgroundColor: "white.100",
};

export { Table };
