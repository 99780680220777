import React from "react";
import BreadcrumbsV2 from "../breadcrumbsV2";
import { Logo } from "./logo";
import { Flex, Box, Container, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { ACTIVATION_BREAKCRUMBS_TITLE } from "./constants";
import { MarkupToHtml } from "../../helpers/parse";

export const CanceledScreen = ({ data, headline, paragraph, breadcrumbsText }) =>
{
  const date = data.lastChangeDateTime && new Date(data.lastChangeDateTime) || new Date();
  const formatedDate = `${date.toLocaleDateString("cs-CZ")} v ${date.toLocaleTimeString("cs-CZ", {
    hour: "2-digit",
    minute: "2-digit",
  })}`
  const paragraphs = paragraph.replace("{{date}}", formatedDate);

  return (
    <Wrapper backgroundColor="lightGrey" pb="95px" minHeight={"calc(100vh - 497px)"}>
      <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
        <Container px={["15px", null, null, null, null]}>
          <BreadcrumbsV2
            pages={[
              {
                name: ACTIVATION_BREAKCRUMBS_TITLE(breadcrumbsText),
                link: null,
              },
            ]}
            color={"exploratoryGreen"}
          />
        </Container>
        <Container px={["15px", null, null, null, null]} position={"relative"}>
          <Box position={"absolute"} top={0} right={49}>
            <Logo />
          </Box>
        </Container>
        <Container alignItems={"center"} px={["15px", null, null, null, null]} position={"relative"}>
          <Heading pt={"20vh"} as="h1" fontSize={[7, null, 9]} color="exploratoryGreen" mb={30} textAlign={"center"}>
            {headline}
          </Heading>
          {paragraphs.split("\n").map((paragraph, index) =>
            <Text key={`paragraph-${index}`} as="p" fontSize={4} mb={15} color="exploratoryGreen" fontWeight="normal" textAlign={"center"}>
              <MarkupToHtml>{paragraph}</MarkupToHtml>
            </Text>)}
        </Container>
      </Flex>
    </Wrapper>
  )
};
