import React, { Fragment } from "react";
import { Logo } from "./logo";
import { Flex, Box, Container, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { MarkupToHtml } from "../../helpers/parse";

export const UnavailableScreen = ({ headline, paragraph }) =>
{
    return (
        <Wrapper backgroundColor="lightGrey" pb="95px" pt="35px" minHeight={"calc(100vh - 497px)"}>
            <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
                <Container px={["15px", null, null, null, null]} position={"relative"}>
                    <Box position={"absolute"} top={0} right={49}>
                        <Logo />
                    </Box>
                </Container>
                <Container alignItems={"center"} px={["15px", null, null, null, null]} position={"relative"}>
                    <Heading pt={"20vh"} as="h1" fontSize={[7, null, 9]} color="exploratoryGreen" mb={30} textAlign={"center"}>
                        <MarkupToHtml>{headline}</MarkupToHtml>
                    </Heading>
                    {paragraph.split("\n").map((paragraph, index) =>
                        <Text key={`paragraph-${index}`} as="p" fontSize={4} mb={15} color="gray" fontWeight="normal" textAlign={"center"}>
                            <MarkupToHtml components={{
                                strong: ({ children }) => <Text key={`paragraph-${index}`} as="strong" fontSize={4} mb={15} color="expertiseGray" fontWeight="bold" textAlign={"center"}>{children}</Text>,
                            }}>{paragraph}</MarkupToHtml>
                        </Text>)}
                </Container>
            </Flex>
        </Wrapper>
    )
}
