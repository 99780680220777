import React from "react";
import BreadcrumbsV2 from "../breadcrumbsV2";
import { Logo } from "./logo";
import { Flex, Box, Container, Wrapper } from "../../style/Grid";
import { Table } from "../../style/Table/index";
import { Button } from "../../style/Button";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { ACTIVATION_BREAKCRUMBS_TITLE } from "./constants";
import { MarkupToHtml } from "../../helpers/parse";

export const InactiveScreen = ({ data, action, headline, paragraph, breadcrumbsText }) =>
{
  const pdfLink = data?.supplyPoint?.pricelistPdfLink || undefined;

  return (
    <Wrapper background="linear-gradient(180deg, #A8BE32 0%, #2B6E33 100%);" pb="95px" minHeight={"calc(100vh - 497px)"}>
      <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
        <Container px={["15px", null, null, null, null]}>
          <BreadcrumbsV2
            pages={[
              {
                name: ACTIVATION_BREAKCRUMBS_TITLE(breadcrumbsText),
                link: null,
              },
            ]}
            color={"white.100"}
          />
        </Container>
        <Container px={["15px", null, null, null, null]} position={"relative"}>
          <Box position={"absolute"} top={0} right={49}>
            <Logo variant={"light"} />
          </Box>
        </Container>
        <Container alignItems={"center"} px={["15px", null, null, null, null]} position={"relative"}>
          <Heading pt={"20vh"} as="h1" fontSize={[7, null, 9]} color="white.100" mb={30} textAlign={"center"}>
            <MarkupToHtml>{headline}</MarkupToHtml>
          </Heading>
          {paragraph.split("\n").map((paragraph, index) =>
            <Text key={`paragraph-${index}`} as="p" fontSize={3} mb={30} color="white.100" fontWeight="normal" textAlign={"center"}>
              <MarkupToHtml>{paragraph}</MarkupToHtml>
            </Text>)}
          <Container maxWidth="650px" alignItems={"center"} px={["15px", null, null, null, null]}>
            <Flex>
              <Button
                as={"a"}
                href={pdfLink}
                target={"_blank"}
                variant="white"
                height="52px"
                width={"285px"}
                fontSize="16px"
                fontWeight="600"
                mr={"16px"}
                mt={"25px"}
                mb={"80px"}
              >
                Zobrazit ceník a podmínky
              </Button>
              <Button as={"a"} type="submit" height="52px" width={"285px"} fontSize="16px" fontWeight="600" mt={"25px"} mb={"80px"} onClick={action}>
                Aktivovat
              </Button>
            </Flex>
          </Container>
          <Box mt={"0"} width={"580px"}>
            <Table>
              <tbody>
                <tr>
                  <th>Adresa odběrného místa</th>
                  <th>EAN/EIC kód</th>
                  <th>Číslo smlouvy</th>
                </tr>
                <tr>
                  <td>{data.supplyPoint?.address.inline}</td>
                  <td>{data.supplyPoint?.code}</td>
                  <td>{data.supplyPoint?.contractNumber}</td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Container>
      </Flex>
    </Wrapper>
  );
};
