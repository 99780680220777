import React from "react";
import { Wrapper, Flex } from "../../style/Grid";
import { Button } from "../../style/Button";
import
{
    ERROR_STATE,
    CANCELED_STATE,
    INACTIVE_STATE,
    ACTIVE_STATE,
    UNAVAILABLE_STATE,
} from "./constants";

export const TestControl = ({ setState }) => (
    <Wrapper>
        <Flex flex={"1"} flexDirection={"row"} alignItems="center" overflow="scroll" mb="0">
            {
                [
                    ERROR_STATE,
                    CANCELED_STATE,
                    INACTIVE_STATE,
                    ACTIVE_STATE,
                    UNAVAILABLE_STATE,
                ].map(STATE => (
                    <Button
                        key={STATE}
                        as={"a"}
                        mx="5px"
                        onClick={() => setState(STATE)}
                    >
                        {STATE.toLocaleUpperCase()}
                    </Button>
                ))
            }
        </Flex>
    </Wrapper>
);