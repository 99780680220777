export const DATA_API_BASE_URL = "/p/api/v1/";
export const ACTIVATION_BREAKCRUMBS_TITLE = priceListText => `Aktivace ${priceListText}`;

export const FETCHING_STATE = "fetching";
export const ERROR_STATE = "error";
export const DONE_STATE = "done";
export const CANCELED_STATE = "canceled";
export const FAILED_STATE = "failed";
export const INACTIVE_STATE = "inactive";
export const ACTIVE_STATE = "active";
export const UNAVAILABLE_STATE = "unavailable";
export const INVALID_TOKEN_STATE = "invalid_token";

export const STATES = [
  FETCHING_STATE,
  ERROR_STATE,
  DONE_STATE,
  CANCELED_STATE,
  FAILED_STATE,
  INACTIVE_STATE,
  ACTIVE_STATE,
  UNAVAILABLE_STATE,
  INVALID_TOKEN_STATE,
];

export const SHOW_TEST_PANEL = false;
