import React from "react";
import BreadcrumbsV2 from "../breadcrumbsV2";
import { Logo } from "./logo";
import { Flex, Container, Wrapper, Box } from "../../style/Grid";
import { Button } from "../../style/Button";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Line } from "./line";
import { ACTIVATION_BREAKCRUMBS_TITLE } from "./constants";
import { MarkupToHtml } from "../../helpers/parse";

export const DoneScreen = ({ data, action, headline, paragraph, paragraph2, breadcrumbsText }) =>
{
  const date = data.lastChangeDateTime && new Date(data.lastChangeDateTime) || new Date()
  const formatedDate = `${date.toLocaleDateString("cs-CZ")} v ${date.toLocaleTimeString("cs-CZ", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  const paragraphs = paragraph.replace("{{date}}", formatedDate);
  const paragraphs2 = paragraph2.replace("{{date}}", formatedDate);

  return (
    <Wrapper background="linear-gradient(180deg, #A8BE32 0%, #2B6E33 100%);" pb="95px" minHeight={"calc(100vh - 497px)"}>
      <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
        <Container px={["15px", null, null, null, null]}>
          <BreadcrumbsV2
            pages={[
              {
                name: ACTIVATION_BREAKCRUMBS_TITLE(breadcrumbsText),
                link: null,
              },
            ]}
            color={"white.100"}
          />
        </Container>
        <Container px={["15px", null, null, null, null]} position={"relative"}>
          <Box position={"absolute"} top={0} right={49}>
            <Logo variant={"light"} />
          </Box>
        </Container>
        <Container alignItems={"center"} px={["15px", null, null, null, null]} position={"relative"}>
          <Heading pt={"20vh"} as="h1" fontSize={[7, null, 9]} color="white.100" mb={30} textAlign={"center"}>
            {headline}
          </Heading>
          {paragraphs.split("\n").map((paragraph, index) =>
            <Text key={`paragraph-${index}`} as="p" fontSize={4} mb={20} color="white.100" fontWeight="normal" textAlign={"center"}>
              <MarkupToHtml>{paragraph}</MarkupToHtml>
            </Text>)}
          <Line />
          {paragraphs2.split("\n").map((paragraph, index) =>
            <Text key={`paragraph-${index}`} fontSize={1} mb={15} color="white.100" fontWeight="normal" textAlign={"center"}>
              <MarkupToHtml>{paragraph}</MarkupToHtml>
            </Text>)}
          <Container maxWidth="650px" alignItems={"center"} px={["15px", null, null, null, null]}>
            <Button
              as={"a"}
              type="submit"
              variant="white"
              height="52px"
              width={"320px"}
              fontSize="16px"
              fontWeight="600"
              mx="25px"
              mt={"10px"}
              mb={"80px"}
              onClick={action}
            >
              Zrušit aktivaci
            </Button>
          </Container>
        </Container>
      </Flex>
    </Wrapper>
  )
};
