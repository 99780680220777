import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Logo = ({ variant }) => {
    const isLight = variant === "light";

    return (isLight ?
        <StaticImage
            placeholder={"tracedSVG"}
            src={"../../images/hp-stamp-white.svg"}
            alt={"Tradice těžby"}
            height={116}
            width={127}
        /> :
        <StaticImage
            placeholder={"tracedSVG"}
            src={"../../images/hp-stamp.svg"}
            alt={"Tradice těžby"}
            height={116}
            width={127}
        />
    )
}